@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
	.scrollbar-custom::-webkit-scrollbar {
		@apply bg-gray-800 w-1;
	}

	.scrollbar-custom::-webkit-scrollbar-thumb {
		@apply bg-gray-500 rounded-lg;
	}

	.scrollbar-custom::-webkit-scrollbar-button {
		@apply hidden;
	}

	.ncItem {
		contain-intrinsic-size: auto 1px auto 120px;
		content-visibility: auto;
	}

	.ncItemAd {
		contain-intrinsic-size: auto 1px auto 210px;
		content-visibility: auto;
	}
}

@layer base {
	.javascriptEnabled .noScript,
	.javascriptEnabled noscript {
		@apply hidden w-0 h-0;
	}

	.javascriptDisabled .withScript {
		@apply hidden w-0 h-0;
	}

	/* Generated by Font Squirrel (https://www.fontsquirrel.com) on October 25, 2016 */
	@font-face {
		font-family: minecrafter;
		src:
			url("./fonts/minecrafter.woff2") format("woff2"),
			url("./fonts/minecrafter.woff") format("woff");
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}
}
